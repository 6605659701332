import React from 'react';
import styles from "./pricing.module.scss"
import cardStyles from "../card/card.module.scss"
import { makeAffiliatePurchaseUrl, addParamToUrl } from "../../lib/urlParams"
import ContactPopup from "../modals/ContacPopup"
import Button from "../button"
import classnames from "../../helpers/classnames"

class Pricing extends React.Component {
  state = {
    isHover: false,
    videoCheckoutUrl: '',
    completeCheckoutUrl: '',
    ebookCheckoutUrl: '',
    contactModalIsOpen: false
  };

  handleOpenContactModal =() => this.setState({ contactModalIsOpen: true })
  handleCloseContactModal =() => this.setState({ contactModalIsOpen: false })


  componentDidMount() {
    const { ebook, video, complete } = this.props.checkoutUrls

    if (typeof window !== 'undefined') {
      const videoCheckoutUrl = makeAffiliatePurchaseUrl(video.url, typeof window !== 'undefined' ? window.location.search : '')
      const completeCheckoutUrl = makeAffiliatePurchaseUrl(complete.url, typeof window !== 'undefined' ? window.location.search : '')
      const ebookCheckoutUrl = makeAffiliatePurchaseUrl(ebook.url, typeof window !== 'undefined' ? window.location.search : '')

      this.setState({
        videoCheckoutUrl,
        completeCheckoutUrl,
        ebookCheckoutUrl
      })
    }

  }

  handleMouseIn () {
    this.setState ({isHover: true});
  }

  handleMouseOut () {
    this.setState ({isHover: false});
  }
  render () {
    const {isHover} = this.state;

    // !this block may cause perfomance issues!
    const { ebook, video, complete } = this.props.checkoutUrls

    const {
      videoCheckoutUrl,
      completeCheckoutUrl,
      ebookCheckoutUrl
    } = this.state

    const videoPrice = video.price
    const completePrice = complete.price
    const ebookPrice=  ebook.price

    // in case, we're in sales mode
    const videoOldPrice = video.oldPrice
    const completeOldPrice = complete.oldPrice
    const ebookOldPrice=  ebook.oldPrice

    return (
      <>
        <ContactPopup
          show={this.state.contactModalIsOpen}
          handleClose={this.handleCloseContactModal}
        />

        <div className={styles.cardsList} id="pricing">
          <div className={styles.card}>
            <h3 className={styles.card__title}>
              Video lectures
            </h3>

            <ul className={styles.card__text}>
              <li>
                <strong>8h video lectures</strong>
                <br />
                (English & Portuguese subs)
              </li>
            </ul>

            {video.oldPrice != '' &&
            <div>
              <h4 className={styles.card__oldPrice}>{videoOldPrice}</h4>
            </div>
            }
            <h4 className={styles.card__price}>{videoPrice}</h4>
            <a
              className={styles.card__btn}
              href={videoCheckoutUrl}
              id="main-video-buy"
              target="_blank"
              title=""
            >
              ENROLL NOW
            </a>
          </div>

          <div id="pricing-complete-masterclass" className={classnames(
            styles.card,
            styles.cardBig,
            styles.cardOutlined,
            styles.cardMoneybackGuarantee
          )}>
            <h3 className={styles.card__title}>
              Complete Masterclass
            </h3>

            <ul className={styles.card__text}>
              <li>
                <strong>8h video lectures </strong>
                <br />
                (English & Portuguese subs)
              </li>
              <li>
                <strong>14 eBooks</strong> (in total 450+ pages)
              </li>
              <li>
                <strong>Ideation prompts</strong>
              </li>
              <li>
                <strong>Certificate</strong>
              </li>
            </ul>

            <div style={{ marginTop: 'auto' }}>
              {
                complete.oldPrice !== '' &&
                <div>
                  <h4 className={styles.card__oldPrice}>{completeOldPrice}</h4>
                </div>
              }

              <h4 className={styles.card__newPrice} style={{color: complete.oldPrice === '' ? 'inherit' : '#FF1A2A'}}>
                {completePrice}
              </h4>
              <Button
                href={completeCheckoutUrl}
                id="main-complete-buy"
                target="_blank"
                style={{width: "200px", marginTop: "30px"}}
                border
              >
                ENROLL NOW
              </Button>

            </div>
          </div>
          <div className={classnames(
            styles.card,
            styles.cardBig,
            styles.cardMoneybackGuarantee
          )}>
            <h3 className={styles.card__title}>
              Complete Masterclass
            </h3>

            <ul className={styles.card__text}>
              <li>
                <strong>8h video lectures </strong>
                <br />
                (English & Portuguese subs)
              </li>
              <li>
                <strong>14 eBooks</strong> (in total 450+ pages)
              </li>
              <li>
                <strong>Ideation prompts</strong>
              </li>
              <li>
                <strong>Certificate</strong>
              </li>
            </ul>
            <h4 className={styles.card__price}>
              Custom price for 5+ licenses
            </h4>
            <button
              id="main-custom-contact"
              className={styles.card__btn}
              onClick={this.handleOpenContactModal}
              title=""
              type="button"
            >
              CONTACT US
            </button>
          </div>
          <div className={styles.card}>
            <h3 className={styles.card__title}>eBooks</h3>
            <ul className={styles.card__text}>
              <li>
                <strong>14 eBooks </strong>(in total 450+ pages)
              </li>
              <li>
                <strong>Ideation prompts</strong>
              </li>
            </ul>

            {
              ebook.oldPrice !== '' &&
              <h4 className={styles.card__oldPrice}>{ebookOldPrice}</h4>
            }
            <h4 className={styles.card__price}>{ebookPrice}</h4>
            <a
              className={styles.card__btn}
              href={ebookCheckoutUrl}
              id="main-ebooks-buy"
              target="_blank"
              title=""
            >
              ENROLL NOW
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Pricing;
