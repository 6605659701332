import Cookies from 'js-cookie'

/**
 * Appends url query param to `url`
 * @param {*} url 
 * @param {*} key 
 * @param {*} value 
 */
export function addParamToUrl(url, key, value) {
  const _url = new URL(url)
  const base = _url.protocol + '//' + _url.host + _url.pathname
  const queryParams = new URLSearchParams(_url.search)
  queryParams.append(key, value)

  return base + '?' + queryParams.toString()
}

/**
 * Adds coupon_code parameter to `url`
 * Used in quiz popup
 * @param {*} url 
 * @param {*} couponCode 
 */
export function addCouponCodeToUrl(url, couponCode) {
  const COUPON_CODE_COOKIE_KEY = 'coupon_code'
  return addParamToUrl(url, COUPON_CODE_COOKIE_KEY, couponCode)
}

/**
 * Handles teachable cookie / url param affiliate codes
 * Transforms Checkout url (targetUrl) into url with affiliate parameter
 * if its present
 * @param {*} targetUrl 
 */
export function makeAffiliatePurchaseUrl(targetUrl, searchQuery) {
  const AFFILIATE_PARAMETER_KEY = 'affcode'
  const AFFILIATE_COOKIE_KEY = 'affiliate_code'
  const AFFILIATE_COOKIE_EXPIRE = 30 // expires in days

  // Prepare URL parts for later use
  const _url = new URL(targetUrl)
  const targetBaseUrl = _url.host + _url.pathname
  const targetQueryParams = new URLSearchParams(_url.search)
  
  // Get affiliate code if exists
  const currentUrlQueryParams = new URLSearchParams(searchQuery)

  let affiliateCode = null
  if (Cookies.get(AFFILIATE_COOKIE_KEY)) {
    // Try to retrieve affiliate code from cookies
    // When visitor comes later
    affiliateCode = Cookies.get(AFFILIATE_COOKIE_KEY)
  } else if (currentUrlQueryParams.has(AFFILIATE_PARAMETER_KEY)) {
    // Try to retrieve affiliate code from URL parameter and also stores it in cookie.
    affiliateCode = currentUrlQueryParams.get(AFFILIATE_PARAMETER_KEY)
    Cookies.set(AFFILIATE_COOKIE_KEY, affiliateCode, { expires: AFFILIATE_COOKIE_EXPIRE })
  } else {
    // There was no affiliate code in any shape or form.
    return targetUrl
  }

  // There was an affiliate code, append it into URL
  targetQueryParams.append(AFFILIATE_PARAMETER_KEY, affiliateCode)
  
  // Create new url and return
  return _url.protocol + '//' + targetBaseUrl + '?' + targetQueryParams.toString()
}

